<template>
  <container id="how" :title="t('title')" :subtitle="t('subtitle')" color="blue-light">
      <div  class="how">
          <item-subject v-for="item in data" :data="item" class="how__item"></item-subject>
      </div>
  </container>
</template>

<script>
import Container from './Container'
import ItemSubject from '@/components/item-subject'
import Svg from './utils/Svg';

import {useI18n} from 'vue-i18n';
import { computed } from 'vue';



export default {
    components:{
        Container,
        ItemSubject,
        Svg
    },
    setup(){
        const {t} = useI18n({
            messages:{
              en: require('../locales/en/how.json'),
              fr: require('../locales/fr/how.json')
            }
        })

        const data = computed(() => {
            return [
                {
                    color: "blue",
                    icon: "automation",
                    title: t('features[0].title'),
                    text: t('features[0].p')
                },
                {
                    color: "blue-dark",
                    icon: "database",
                    title:t('features[1].title'),
                    text: t('features[1].p')
                },
                {
                    color: "blue-grey",
                    icon: "code",
                    title:t('features[2].title'),
                    text: t('features[2].p')
                }
            ];
        })
       


        return {data, t};
    }

}
</script>

<style lang="scss">
@import '../style/main';

.how{
    display: flex;
    justify-content: space-between;

    @include respond(phone){
        flex-direction: column;
    }
    &__item{
        flex: 0 0 31%;
    }
}

</style>