<template>
    <div class="sub" :class="`sub--${data.color}`">
            <div class="sub__logo">
                <Svg :icon="data.icon" className="sub__logo--svg"/>
            </div>
            <h4>
                {{data.title}}
            </h4>
            <p>
                {{data.text}}
            </p>
        </div>
</template>

<script>
import Svg from '@/components/utils/Svg';
export default {
    components:{
        Svg
    },
    props:['data']
}
</script>

<style lang='scss'>
@import '@/style/main';


.sub{
    // flex: 0 0 31%;
    padding:  4rem 3rem;
    color:$color-white;

    @include respond(phone){
        &:not(:last-child){
            margin-bottom: 2rem;
        }
    }

    & h4{
        margin-bottom: 1.6rem;
        text-transform: uppercase;
        letter-spacing: .1rem;
        font-weight: 400;
        font-size: 1.6rem;
    }

    & p {
        font-weight: 300;
        text-align: justify;
    }
    &--blue{
        background-color: $color-blue-main;
    }

    &--blue-dark{
        background-color: $color-blue-dark;
    }

    &--blue-grey{
        background-color: $color-blue-grey;
    }
    &__logo{
        width: 8rem;
        height: 8rem;
        border-radius: 50%;
        background-color: rgba(255,255,255,.4);
        margin-bottom: 3rem;

        display: flex;
        align-items: center;
        justify-content: center;
        &--svg{
            @include svg(4rem);
        }
    }
}

</style>